/**
 * Created by Mikhail Menshenin on 30.08.2024
 */

AnimationsLibrary.countTo = function (node, newValue, options) {
    var duration = options.duration || 1;
    var currentValue, total, divider;
    var currentText = node.getString && node.getString();
    var numbers = currentText.match(/\d+/g);
    currentValue = options.from !== undefined ? options.from : parseInt(numbers[0]);
    total = numbers && parseInt(numbers[1]) || 0;
    divider = currentText.replace(/\d+/g, "");

    var FPS = 30;
    var delta = newValue - currentValue;
    var steps = Math.min(Math.ceil(FPS * duration), Math.abs(delta));
    var increment = delta / steps;
    var stepDuration = duration / steps;
    var getString = typeof options.getString === "function" ? options.getString : function (value, divider, total) {
        return total ? value + divider + total : value;
    };
    var updateValueAction = function (value) {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                node.setString(getString(value, divider, total));
                if (options.sound) {
                    cleverapps.audio.playSound(options.sound);
                }
            }),
            new cc.DelayTime(stepDuration)
        );
    };
    var actions = [];
    for (var i = 0; i < steps; i++) {
        currentValue += increment;
        actions.push(updateValueAction(Math.ceil(currentValue)));
    }

    return actions.length > 1 ? new cc.Sequence(actions) : actions[0];
};
