/**
 * Created by mac on 8/20/23
 */

var ResultsBarView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
    },

    init: function (game) {
        this.game = game;
        var score = game.score;
        var isDouble = this.isDouble = game.opponentScore !== undefined;
        var styles = cleverapps.styles.ResultsBar;

        this.setContentSize2(isDouble ? styles.double : styles.single);

        var bg = cleverapps.UI.createScale9Sprite(bundles.game.frames.score_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setAnchorPoint(0, 0);
        bg.setContentSize(this.width, this.height);
        this.addChild(bg);

        var person = this.createPerson(0);
        person.setPositionRound(styles.person);
        this.addChild(person);

        if (game.goal) {
            var goalText = "ResultsBar.Goal";
            var scoreBlockGoal = this.scoreBlock(score, ResultsBarView.GOAL, goalText, true);
            scoreBlockGoal.setAnchorPoint(0, 0);
            scoreBlockGoal.setPositionRound(0, 0);
            this.addChild(scoreBlockGoal);
        } else {
            var labelText = "ResultsBar.YourScore";
            var scoreBlock = this.scoreBlock(score, ResultsBarView.SOLO, labelText, true);
            scoreBlock.setAnchorPoint(0, 0);
            scoreBlock.setPositionRound(0, 0);
            this.addChild(scoreBlock);

            if (isDouble) {
                var personOpponent = this.createPerson(1);
                personOpponent.setPositionRound(styles.person2);
                this.addChild(personOpponent);

                labelText = "ResultsBar.OpponentScore";
                var scoreBlockOpponent = this.scoreBlock(score, ResultsBarView.DOUBLE, labelText, false);
                scoreBlockOpponent.setAnchorPoint(0, 0);
                scoreBlockOpponent.setPositionRound(person.width, 0);
                this.addChild(scoreBlockOpponent);

                scoreBlock.setPositionRound(this.width / 2, 0);
            }
        }

        cleverapps.UI.onClick(this, this.addScore.bind(this), {
            interactiveScale: false
        });

        this.setVisible(false);
        this.game.on("showResults", this.animateShow.bind(this), this);
        this.game.on("hideResults", this.animateHide.bind(this), this);
    },

    scoreBlock: function (score, type, labelText, active) {
        var styles = cleverapps.styles.ResultsBar.scoreBlock[type];

        var block = new cc.Node();
        var width = type === ResultsBarView.VERSUS ? this.width / 2 : this.width;
        var blockWidth = width - cleverapps.styles.ResultsBar.personWidth;
        if (this.game.getMissionType() === Mission.TYPE_LETTER) {
            blockWidth -= cleverapps.styles.ResultsBar.info.width;
        }
        block.setContentSize2(blockWidth, this.height);
        block.setAnchorPoint(0.5, 0.5);
        block.setCascadeOpacityEnabled(true);

        var bg = cleverapps.UI.createScale9Sprite(bundles.game.frames.score_bg_results, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setAnchorPoint(0, 0);
        bg.setContentSize2(block.getContentSize());
        block.addChild(bg);

        cleverapps.styles.FONTS.SCORE_TEXT = type === ResultsBarView.VERSUS ? cleverapps.styles.FONTS.SCORE_TEXT_DOUBLE : cleverapps.styles.FONTS.SCORE_TEXT_SINGLE;
        var scoreView = new ScoreView(score);
        scoreView.setPositionRound(styles.scoreView);
        block.addChild(scoreView);
        if (active) {
            cleverapps.aims.registerTarget("score", scoreView);
        }

        score.on("onUpdate", this.animateScore.bind(scoreView), this);

        var label = cleverapps.UI.generateOnlyText(labelText, cleverapps.styles.FONTS[type === ResultsBarView.VERSUS ? "RESULTS_DOUBLE_TEXT" : "RESULTS_TEXT"]);
        label.setPositionRound(styles.label);
        block.addChild(label);

        if (type === ResultsBarView.GOAL) {
            var goalText = "/" + this.game.goal.target;
            var labelGoal = cleverapps.UI.generateOnlyText(goalText, cleverapps.styles.FONTS.GOAL_TEXT);
            labelGoal.setPositionRound(styles.goal);
            block.addChild(labelGoal);
        }

        if (!active) {
            bg.setVisible(false);
            block.setOpacity(100);
        }

        return block;
    },

    animateShow: function (silent) {
        this.setVisible(true);

        if (silent) {
            return;
        }

        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.ResultsBar.showAnimation.vertical : cleverapps.styles.ResultsBar.showAnimation.horizontal;

        this.setCascadeOpacityEnabledRecursively(true);
        this.setOpacity(0);

        this.runAction(
            new cc.Sequence(
                new cc.MoveBy(0, styles.dx, styles.dy),
                new cc.Spawn(
                    new cc.FadeIn(0.3),
                    new cc.MoveTo(0.3, this.x, this.y)
                )
            )
        );
    },

    createPerson: function (id) {
        var person = new cc.Node();
        person.setAnchorPoint(0.5, 0.5);

        var frame = new cc.Sprite(bundles.avatars.frames.frame_user);
        person.setContentSize(frame.getContentSize());

        var avatar = new cc.Sprite(id === 1 ? bundles.avatars.frames["opponent_" + Math.floor(Math.random() * 3 + 1)] : bundles.avatars.frames.avatar_user);
        avatar.setPositionRound(person.width / 2, person.height / 2);
        person.addChild(avatar);

        frame.setPositionRound(person.width / 2, person.height / 2);
        person.addChild(frame);
        return person;
    },

    animateHide: function () {
        this.setCascadeOpacityEnabledRecursively(true);

        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.ResultsBar.showAnimation.vertical : cleverapps.styles.ResultsBar.showAnimation.horizontal;
        this.runAction(
            new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.MoveBy(0.3, styles.dx, styles.dy)
            )
        );
    },

    addScore: function () {
        Game.currentGame.score.addPoints(50);
    },

    animateScore: function (points) {
        this.scoreView.runAction(AnimationsLibrary.countTo(this.scoreView, points, {
            duration: 0.4,
            sound: bundles.game.urls.yatzy_check
        }));
        this.scoreView.fitTo(cleverapps.styles.UI.ScoreView.maxWidth);
    }
});

cleverapps.styles.UI.ScoreView.maxWidth = 150;

cleverapps.styles.ResultsBar = {
    single: {
        width: 900,
        height: 165
    },

    double: {
        width: 900,
        height: 165
    },

    showAnimation: {
        horizontal: {
            dx: 100,
            dy: 0
        },
        vertical: {
            dx: -100,
            dy: 0
        }
    },

    personWidth: 175,

    scoreBlock: {
        goal: {
            scoreView: {
                x: { align: "center", dx: -10 },
                y: { align: "center", dy: 2 }
            },

            label: {
                x: { align: "center", dx: -150 },
                y: { align: "center", dy: -5 }
            },

            goal: {
                x: { align: "center", dx: 130 },
                y: { align: "center", dy: 5 }
            }
        },
        single: {
            scoreView: {
                x: { align: "center", dx: 150 },
                y: { align: "center", dy: 0 }
            },

            label: {
                x: { align: "center", dx: -50 },
                y: { align: "center", dy: 5 }
            }
        },

        double: {
            scoreView: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -20 }
            },

            label: {
                x: { align: "center", dx: -0 },
                y: { align: "center", dy: 40 }
            }
        }
    },

    person: {
        x: { align: "right" },
        y: { align: "center" }
    },

    person2: {
        x: { align: "left" },
        y: { align: "center" }
    },

    info: {
        width: 200
    }
};

ResultsBarView.SOLO = "single";
ResultsBarView.VERSUS = "double";
ResultsBarView.GOAL = "goal";