/**
 * Created by slava on 02.02.17.
 */

var levels = {};

levels.User = function (isNewUser) {
    cleverapps.User.call(this, isNewUser);

    connector.platform.on("userIdChanged", this.onUserIdChanged.bind(this));
};

levels.User.prototype = Object.create(cleverapps.User.prototype);
levels.User.prototype.constructor = levels.User;

levels.User.prototype.getProgress = function () {
    return cleverapps.humanReadableNumber(this.episode, this.level) - 1;
};

levels.User.prototype.getVirtualProgress = function () {
    return cleverapps.humanReadableNumber(this.episode, this.level) + this.progress - 1;
};

levels.User.prototype.loadData = function (info) {
    cleverapps.User.prototype.loadData.call(this, info);
    this.episode = info.episode;
    this.level = info.level;
    this.league = this.calcLeague();
    this.progress = info.progress || 0;

    if (info.uniqueStartEvent) {
        this.uniqueStartEvent = info.uniqueStartEvent;
    }

    this.migrateEpisode();

    this.calcRating();
};

levels.User.prototype.calcRating = function () {
    var newLeague = this.calcLeague();
    if (this.league !== newLeague) {
        this.league = newLeague;
        this.isLeagueChanged = true;
        this.onLeagueChangeListener();
    }
    this.save();
};

levels.User.prototype.isUniqueStart = function (episodeNo, levelNo, version) {
    var uniqueStartEvent = episodeNo + "_" + levelNo + "_" + version;

    if (this.uniqueStartEvent === uniqueStartEvent) {
        return false;
    }

    this.uniqueStartEvent = uniqueStartEvent;
    this.save(false);

    return true;
};

levels.User.prototype.getPassAllProgress = function () {
    return this.progress;
};

levels.User.prototype.isCurrentLevel = function (levelNo, episodeNo) {
    if (cleverapps.meta.getType() !== Metha.HOSE) {
        return true;
    }
    var currentLevel = this.getCurrentLevel();
    return currentLevel.levelNo === levelNo && currentLevel.episodeNo === episodeNo;
};

levels.User.prototype.isPassedLevel = function (levelNo, episodeNo) {
    return this.episode > episodeNo || this.episode === episodeNo && this.level > levelNo;
};

levels.User.prototype.getHumanReadableNumber = function () {
    return cleverapps.humanReadableNumber(this.episode, this.level);
};

levels.User.prototype.checkAvailable = function (available, options) {
    available = available || {};
    options = options || [];

    var projectName = cleverapps.config.name;
    if (available[projectName]) {
        return this.checkAvailable(available[projectName], options);
    }

    if (cleverapps.config.subtype && available[cleverapps.config.subtype]) {
        return this.checkAvailable(available[cleverapps.config.subtype], options);
    }

    var source = connector.info.source;
    if (available[source]) {
        return this.checkAvailable(available[source], options);
    }

    if (available.registeredDate && this.registered < new Date(available.registeredDate).getTime()) {
        return false;
    }

    if (available.registeredBefore && this.registered >= new Date(available.registeredBefore).getTime()) {
        return false;
    }

    if (!cleverapps.config.debugMode && available.registered && this.registered + cleverapps.parseInterval(available.registered) > Date.now()) {
        return false;
    }

    if (available.disabled) {
        return false;
    }

    if (available.debugMode && !cleverapps.config.debugMode) {
        return false;
    }

    available.level = available.level || 0;
    available.episode = available.episode || 0;

    if (available.projectName) {
        available.projectName = cleverapps.toArray(available.projectName);
        if (available.projectName.indexOf(projectName) === -1) {
            return false;
        }
    }
    if (available.types && available.types.indexOf(cleverapps.config.type) === -1) {
        return false;
    }
    if (available.meta && available.meta.indexOf(cleverapps.meta.getType()) === -1) {
        return false;
    }
    if (available.feature && (cleverapps.config.features || []).indexOf(available.feature) === -1) {
        return false;
    }
    if (available.source) {
        if (!Array.isArray(available.source)) {
            available.source = [available.source];
        }
        if (available.source.indexOf(source) === -1) {
            return false;
        }
    }

    if (available.language && cleverapps.settings.language !== available.language) {
        return false;
    }

    if (available.payments && cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
        return false;
    }

    if (available.paymentsCountry && cleverapps.country.getCountry() !== available.paymentsCountry) {
        return false;
    }

    if (options.ignoreProgress) {
        return true;
    }

    if (available.gameLevel !== undefined) {
        return cleverapps.gameLevel && cleverapps.gameLevel.level >= available.gameLevel;
    }

    return this.getFloatLevel() + 1e-8 >= available.level;
};

levels.User.prototype.getFloatLevel = function (episode, level) {
    if (episode === undefined) {
        episode = this.episode;
    }

    if (level === undefined) {
        level = this.level;
    }

    if (cleverapps.config.type === "merge") {
        return level + Math.min(1, cleverapps.expBySlots[CustomSyncers.SLOT_MAIN].getExp() / cleverapps.gameLevel.nextLevelThreshold(true));
    }

    if (cleverapps.config.regime === "knockout") {
        return 6 * (episode + level / Level.LEVELS_PER_EPISODE);
    }

    return 2 * (episode + level / Level.LEVELS_PER_EPISODE);
};

levels.User.prototype.parseFloatLevel = function (floatingLevel) {
    var data = { episodeNo: 0, levelNo: 0 };

    for (var levelNo = 0, episodeNo = 0; ; ++levelNo) {
        if (cleverapps.config.type !== "merge" && levelNo >= Level.LEVELS_PER_EPISODE) {
            levelNo = 0;
            episodeNo += 1;
        }

        if (this.getFloatLevel(episodeNo, levelNo) > floatingLevel) {
            return data;
        }

        data.episodeNo = episodeNo;
        data.levelNo = levelNo;
    }
};

levels.User.prototype.isPassedAll = function () {
    return cleverapps.isLevels() && this.episode >= this.getEpisodesAmount();
};

levels.User.prototype.incLevel = function () {
    if (this.isPassedAll()) {
        this.progress++;
        if (cleverapps.config.type === "board") {
            var level = this.getCurrentLevel();
            cleverapps.GameSaver.removeSave(cleverapps.GameSaver.getStoreSlot(level.episodeNo, level.levelNo));
        }
        this.trigger("incLevel");
        this.calcRating();
        this.save();
        return;
    }

    this.level++;
    if (this.level === Level.LEVELS_PER_EPISODE) {
        this.level = 0;
        this.incEpisode();
    }
    this.trigger("incLevel");
    this.calcRating();
    this.save();

    if (this.getHumanReadableNumber() === 2 && this.getDaysSinceRegistration() === 0) {
        this.reportRetention();
    }
};

levels.User.prototype.incEpisode = function () {
    this.episode++;
    this.calcRating();
    this.save();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.GENERAL.LEVEL_UP, {
        level: this.episode
    });

    cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.LEVEL, this.episode);
};

levels.User.prototype.getPhysicalLevel = function () {
    return this.getHumanReadableNumber();
};

levels.User.prototype.levelChooseAlgorithm = function (options) {
    var firstLevelNo = options.start;
    var lastLevelNo = levels.User.CalcEpisodesAndLevelsAmount(cleverapps.config.importMode).amountLevels - 1;
    var seed = options.seed;

    var level = cleverapps.Random.random(firstLevelNo, lastLevelNo, seed);

    return Level.CreateByNumber(level, { type: options.type });
};

levels.User.prototype.getRandomLevel = function (seed, type) {
    return this.levelChooseAlgorithm({
        start: cleverapps.config.type === "blocks" ? 15 : 75,
        seed: seed === undefined ? this.progress : seed,
        type: type
    });
};

levels.User.prototype.getCurrentLevel = function () {
    if (this.isPassedAll()) {
        return this.getRandomLevel();
    }

    return new Level(this.episode, this.level);
};

levels.User.prototype.getVirtualLevel = function () {
    return this.getHumanReadableNumber() + this.progress;
};

levels.User.prototype.getVirtualDisplayLevel = function () {
    return this.getVirtualLevel();
};

levels.User.prototype.setLevel = function (level) {
    this.episode = Math.floor(level / 15);
    this.level = level % 15;
    this.progress = 0;

    var total = this.getEpisodesAmount();
    if (this.episode >= total) {
        this.episode = total;
        this.level = 0;
        this.progress = level - this.getHumanReadableNumber() + 1;
    }

    this.save();
};

levels.User.prototype.getPlayerInfo = function () {
    return {
        id: connector.player.id,
        name: connector.player.name || Messages.get("Friends.You"),
        avatar: connector.player.avatar || "#avatars/default_avatar.png",
        firstName: connector.player.firstName,
        lastName: connector.player.lastName
    };
};

levels.User.prototype.onUserIdChanged = function (params) {
    var oldId = params.oldId;
    var newId = params.newId;

    delete this.idfa;
    this.save(false);

    cleverapps.abTest.updateGroups();

    var rumble = cleverapps.isRumble() && cleverapps.meta.getRumble();
    if (rumble) {
        rumble.changePlayerId(oldId, newId);
    }

    var competition = Game.currentGame && Game.currentGame.competition;
    if (competition) {
        competition.changePlayerId(oldId, newId);
    }
    cleverapps.userIdsHistory.add(oldId);

    this.updateIDFA();
};

levels.User.prototype.getSaveInfo = function () {
    var info = cleverapps.User.prototype.getSaveInfo.call(this);
    return Object.assign(info, {
        episode: this.episode,
        level: this.level,
        uniqueStartEvent: this.uniqueStartEvent,
        progress: this.progress
    });
};

levels.User.prototype.updateInfo = function (serverData) {
    delete serverData.source;

    var visited = serverData.visited;

    if (serverData.forcedVisited) {
        serverData.visited = serverData.forcedVisited;
    } else {
        delete serverData.visited;
    }

    var exp = serverData.exp;
    delete serverData.exp;

    cleverapps.expBySlots[CustomSyncers.SLOT_MAIN].load(exp);

    for (var item in serverData) {
        this[item] = serverData[item];
    }

    this.migrateEpisode();

    this.save(false);

    if (visited && !this.lastReportDAU) {
        this.lastReportDAU = this.getDaysSinceRegistration(visited);
    }
};

levels.User.prototype.migrateEpisode = function () {
    if (cleverapps.config.name !== "woodenblock") {
        return;
    }

    // migration for woodenblock users before meta change to simple
    var totalEpisodes = this.getEpisodesAmount();
    if (this.episode >= totalEpisodes) {
        var diff = this.episode - totalEpisodes;
        this.progress += diff * Level.LEVELS_PER_EPISODE + this.level;
        this.episode = totalEpisodes;
        this.level = 0;
    }
};

levels.User.prototype.getInfo = function () {
    return {
        id: connector.platform.getUserID(),
        gold: this.gold,
        soft: this.soft,
        registered: this.registered,
        visited: this.visited,
        source: connector.info.source,
        channel: this.channel,
        progressCompare: this.progressCompare,
        progress: this.progress,
        level: this.level,
        episode: this.episode,
        exp: cleverapps.expBySlots[CustomSyncers.SLOT_MAIN].getExp()
    };
};

levels.User.prototype.initializeNewPlayer = function () {
    cleverapps.User.prototype.initializeNewPlayer.call(this);
    this.episode = 0;
    this.level = 0;
    this.progress = 0;
};

levels.User.prototype.getEpisodesAmount = function () {
    if (this.episodesAmount === undefined) {
        this.episodesAmount = levels.User.CalcEpisodesAmount(cleverapps.config.importMode);
    }
    return this.episodesAmount;
};

levels.User.prototype.resetEpisodesAmount = function () {
    this.episodesAmount = undefined;
};

levels.User.CalcEpisodesAndLevelsAmount = function (withDebug) {
    var amountEpisodes = 0;
    var amountLevels = 0;

    for (var cnt = 0; bundles[Level.BundleId(cnt)] !== undefined; cnt++) {
        var episodeRes = bundles[Level.BundleId(cnt)];
        if (!episodeRes.jsons || (!episodeRes.jsons.levels && !episodeRes.jsons.map_json) || (episodeRes.meta.debug && !withDebug)) {
            break;
        }

        amountEpisodes++;
        amountLevels += episodeRes.meta.episode.levelsAmount;
    }

    return {
        amountEpisodes: amountEpisodes,
        amountLevels: amountLevels
    };
};

levels.User.CalcEpisodesAmount = function (withDebug) {
    var res = levels.User.CalcEpisodesAndLevelsAmount(withDebug);
    return res.amountEpisodes;
};

levels.User.GOLD_FB_CONNECT_REWARD = 100;
