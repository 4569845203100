/**
 * Created by slava on 2/9/19
 */

Mission.TYPE_RAINBOW = 0;
Mission.TYPE_COLLECT_MARK = 1;
Mission.TYPE_BURN_NEARBY = 2;
Mission.TYPE_LANTERN = 9;
Mission.TYPE_LEVELPASS = 15;
Mission.TYPE_BUILDPASS = 15;
Mission.TYPE_BONUS_WORLD = 19;
Mission.TYPE_LIVESFEAST = 20;
Mission.TYPE_SLOT_MACHINE = 21;
Mission.TYPE_SOFTFEAST = 22;
Mission.TYPE_BOOSTTIME = 23;
Mission.TYPE_CHESTTIME = 24;
Mission.TYPE_SALEPASS = 25;
Mission.TYPE_KRAKENFEAST = 26;
Mission.TYPE_SLOT2_PASS = 28;
Mission.TYPE_SLOT1_FEAST = 30;
Mission.TYPE_DRAGONIA_EXPEDITION = "32v1";
Mission.TYPE_SLOT2_FEAST = 33;
Mission.TYPE_SLOT1_PASS = 34;
Mission.TYPE_UNDERSEA_EXPEDITION = "40v1";
Mission.TYPE_CARAVAN = 42;
Mission.TYPE_EXPEDITION_PASS = 44;
Mission.TYPE_EXPEDITION_FEAST = 45;
Mission.TYPE_UNDERSEA2_EXPEDITION = "46v1";
Mission.TYPE_SNAIL_FEAST = 47;
Mission.TYPE_RAPUNZEL_EXPEDITION = 48;
Mission.TYPE_DRAGONIA2_EXPEDITION = 49;
Mission.TYPE_RAPUNZEL2_EXPEDITION = 52;
Mission.TYPE_EASTER_EXPEDITION = "55v1";
Mission.TYPE_ADVENTURE_EXPEDITION = 58;
Mission.TYPE_DRAGONIA3_EXPEDITION = 59;
Mission.TYPE_HALLOWEEN_EXPEDITION = "60v1";
Mission.TYPE_RAPUNZEL3_EXPEDITION = 62;
Mission.TYPE_XMAS_EXPEDITION = "61v1";
Mission.TYPE_CHINA_EXPEDITION = 63;
// Mission.TYPE_BUTTERFLY - 64
// Mission.TYPE_PIGGY_BANK - 65
Mission.TYPE_COLLECTIONS_PASS = 66;
Mission.TYPE_PINBALL = 67;
Mission.TYPE_UNDERSEA3_EXPEDITION = 68;
Mission.TYPE_ADVENTURE2_EXPEDITION = 69;

Mission.OLD_VERSIONS = {};

Object.keys(Mission).forEach(function (name) {
    if (name.indexOf("TYPE_") === 0) {
        var currentVersionType = Mission[name];
        var match = String(currentVersionType).match(/^(\d+)v(\d+)$/);
        if (match) {
            for (var version = Mission.ParseTypeVersion(currentVersionType) - 1; version >= 0; --version) {
                Mission.OLD_VERSIONS[Mission.VersionType(currentVersionType, version)] = currentVersionType;
            }
        }
    }
});

Mission.OBSOLETE_TYPES = [4, 5, 6, 8, 11, 12, 13, 14, 16, 17, 27, 29, 35, 36, 37, 38, 39, 41, 43, 51, 56, 50, 53, 54];

var Missions = {};

Missions.FEATURES = {};

Missions.SEMAPHORE_ALWAYS = 0;
Missions.SEMAPHORE_PRIMARY = 1;
Missions.SEMAPHORE_SECONDARY = 2;
Missions.SEMAPHORE_CUSTOM_LANTERN = 4;
Missions.SEMAPHORE_CUSTOM_PIGGY_BANK = 8;
Missions.SEMAPHORE_CUSTOM_SALE = 16;
Missions.SEMAPHORE_CUSTOM_PRIZE_CALENDAR = 32;
Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1 = 64;
Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2 = 128;
Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4 = 256;

Missions.SEMAPHORE_COOLDOWNS = {};
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_PRIMARY] = "1 hour";
if (cleverapps.config.type === "board") {
    Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_PRIMARY] = "2 day";
}
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_SECONDARY] = "2 day";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_LANTERN] = "5 day";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_PIGGY_BANK] = "2 day";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_PRIZE_CALENDAR] = "3 day";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1] = "0";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2] = "0";
Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4] = "0";

Missions.SEMAPHORE_COOLDOWNS[Missions.SEMAPHORE_CUSTOM_SALE] = "14 day";

Missions.initialize = function () {
    var BaseMissionData = {
        available: {
            level: 3.45
        },
        view: MissionWindow,
        duration: "1 day",
        prize: RewardsConfig.Tournament.places,
        teaser: true
    };

    if (cleverapps.config.type === "match3") {
        Missions[Mission.TYPE_RAINBOW] = Object.assign({}, BaseMissionData, {
            available: {
                level: 4.93
            },
            name: "MagicTournament",
            collectIcon: bundles.tournament_icon.frames.clover_png,
            semaphore: Missions.SEMAPHORE_SECONDARY,
            competition: {
                step: [1, 2],
                amount: [20, 30]
            },
            bundle: "magic_tournament",
            sideBarJson: bundles.sidebar.jsons.tournament_magic_icon_json,
            finishWindow: MissionWindow,
            startWindow: {
                name: "MagicTournamentGuideWindow",
                bundle: bundles.magictournamentguidewindow
            }
        });

        Missions[Mission.TYPE_COLLECT_MARK] = Object.assign({}, BaseMissionData, {
            available: {
                level: 3.6
            },
            name: "RoseTournament",
            collectIcon: bundles.tournament_icon.frames.rose_png,
            semaphore: Missions.SEMAPHORE_SECONDARY,
            competition: {
                step: [10, 40],
                amount: [20, 30]
            },
            bundle: "rose_tournament",
            sideBarJson: bundles.sidebar.jsons.tournament_rose_icon_json,
            finishWindow: MissionWindow,
            startWindow: {
                name: "RoseTournamentGuideWindow",
                bundle: bundles.rosetournamentguidewindow
            }
        });

        Missions[Mission.TYPE_BURN_NEARBY] = Object.assign({}, BaseMissionData, {
            available: {
                level: 4.4
            },
            name: "TulpanTournament",
            collectIcon: bundles.tournament_icon.frames.tulpan_png,
            semaphore: Missions.SEMAPHORE_SECONDARY,
            competition: {
                step: [3, 6],
                amount: [20, 30]
            },
            bundle: "tulpan_tournament",
            sideBarJson: bundles.sidebar.jsons.tournament_tulpan_icon_json,
            finishWindow: MissionWindow,
            startWindow: {
                name: "TulpanTournamentGuideWindow",
                bundle: bundles.tulpantournamentguidewindow
            }
        });
    }

    var features = cleverapps.config.features || [];

    Object.values(Missions.FEATURES).forEach(function (init) {
        init();
    });

    if (typeof LanternWindow !== "undefined") {
        Missions[Mission.TYPE_LANTERN] = Object.assign({}, BaseMissionData, {
            available: {
                level: 8.26
            },
            name: "Lantern",
            view: LanternWindow,
            duration: "2 day",
            semaphore: Missions.SEMAPHORE_CUSTOM_LANTERN,
            startWindow: LanternWindow,
            sideBarJson: bundles.sidebar.jsons.lantern_icon_json,
            iconForce: Forces.LANTERN,
            logic: LanternMissionLogic,
            prize: undefined
        });
    }

    if (features.includes("bonus_world")) {
        Missions[Mission.TYPE_BONUS_WORLD] = Object.assign({}, BaseMissionData, {
            name: "BonusWorld",
            startWindow: BonusWorldWindow,
            view: BonusWorldScene,
            semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1,
            available: {
                level: 4.13
            },
            logic: BonusWorldMissionLogic,
            sideBarJson: BonusWorldConfig.xmas.sideBarJson,
            feature: "bonusworld",
            oncePerEvent: !cleverapps.config.debugMode,
            iconForce: Forces.BONUS_WORLD
        });
    }

    if (cleverapps.config.type === "merge") {
        Missions[Mission.TYPE_LIVESFEAST] = Object.assign({}, BaseMissionData, {
            available: {
                level: 5
            },
            name: "LivesFeast",
            duration: "3 day",
            semaphore: Missions.SEMAPHORE_PRIMARY,
            competition: {
                step: [3, 3, 3, 5, 5, 5, 10, 10, 15, 20, 25],
                forceCoef: 0.45,
                amount: [20, 30],
                noPlayerEpisodeCoef: true,
                botAheadPlayerCoef: 0.5,
                botAheadPlayerBigDifferenceCoef: 0.2,
                bigDistanceLength: 100
            },
            logic: LivesFeast,
            prize: RewardsConfig.LivesFeast.places,
            bundle: "lives_feast",
            collectIcon: function () {
                return cleverapps.skins.getSlot("reward_energy_png") || bundles.reward_icons.frames.reward_energy_png;
            },
            sideBarJson: bundles.sidebar.jsons.lives_feast_icon_json,
            view: LivesFeastMissionWindow,
            startWindow: LivesFeastMissionWindow,
            finishWindow: LivesFeastMissionWindow
        });

        Missions[Mission.TYPE_BUILDPASS] = Object.assign({}, BaseMissionData, {
            name: "BuildPass",
            view: BuildPassWindow,
            semaphore: Missions.SEMAPHORE_PRIMARY,
            startWindow: BuildPassWindow,
            finishWindow: BuildPassWindow,
            rulesOptions: PassRulesOptions,
            logic: PassMissionLogic,
            sideBarJson: bundles.sidebar.jsons.buildpass_icon_json,
            lastChanceView: PassLastChanceWindow,
            collectIcon: bundles.reward_icons_pass.frames.pass_star_png,
            collectItemName: "star",
            duration: "3 days",
            cooldown: "28 days",
            available: {
                level: 5
            }
        });

        Missions[Mission.TYPE_SALEPASS] = Object.assign({}, BaseMissionData, {
            name: "SalePass",
            view: SalePassWindow,
            semaphore: Missions.SEMAPHORE_PRIMARY,
            startWindow: SalePassWindow,
            finishWindow: SalePassWindow,
            rulesOptions: SalePassRulesOptions,
            logic: PassMissionLogic,
            sideBarJson: bundles.sidebar.jsons.salepass_icon_json,
            lastChanceView: PassLastChanceWindow,
            collectIcon: bundles.reward_icons_pass.frames.pass_coin_png,
            collectItemName: "crystal",
            duration: "3 days",
            cooldown: "28 days",
            tutorial: MergeTutorials.salepass,
            available: {
                level: 5
            }
        });

        Missions[Mission.TYPE_CHESTTIME] = Object.assign({}, BaseMissionData, {
            available: {
                level: 9.5
            },
            logic: ChestTime,
            name: "ChestTime",
            duration: "1 day",
            semaphore: Missions.SEMAPHORE_SECONDARY,
            bundle: "chesttime",
            sideBarJson: bundles.sidebar.jsons.chesttime_icon_json,
            view: ChestTimeMissionWindow,
            startWindow: ChestTimeMissionWindow,
            finishWindow: ChestTimeMissionWindow
        });

        Missions[Mission.TYPE_SOFTFEAST] = Object.assign({}, BaseMissionData, {
            available: {
                level: 5
            },
            name: "SoftFeast",
            duration: "3 day",
            semaphore: Missions.SEMAPHORE_PRIMARY,
            competition: {
                step: [5, 5, 15, 18, 25, 30, 35, 50, 65],
                forceCoef: 0.1,
                amount: [20, 30],
                noPlayerEpisodeCoef: true,
                botAheadPlayerCoef: 0.5,
                botAheadPlayerBigDifferenceCoef: 0.2,
                bigDistanceLength: 500
            },
            logic: SoftFeast,
            prize: RewardsConfig.SoftFeast.places,
            bundle: "lives_feast",
            collectIcon: bundles.reward_icons.frames.reward_coin_png,
            sideBarJson: bundles.sidebar.jsons.soft_feast_icon_json,
            view: SoftFeastMissionWindow,
            startWindow: SoftFeastMissionWindow,
            finishWindow: SoftFeastMissionWindow
        });

        Missions[Mission.TYPE_KRAKENFEAST] = Object.assign({}, BaseMissionData, {
            available: {
                level: 5
            },
            name: "KrakenFeast",
            duration: "3 day",
            semaphore: Missions.SEMAPHORE_PRIMARY,
            competition: {
                step: [5, 5, 5, 5, 5, 10, 10, 10, 10, 15, 15, 20, 20, 25, 30],
                forceCoef: 0.2,
                amount: [20, 30],
                noPlayerEpisodeCoef: true,
                botAheadPlayerCoef: 0.5,
                botAheadPlayerBigDifferenceCoef: 0.2,
                bigDistanceLength: 90
            },
            logic: KrakenFeast,
            prize: RewardsConfig.KrakenFeast.places,
            bundle: "lives_feast",
            collectIcon: bundles.reward_icons.frames.tentacle_png,
            sideBarJson: bundles.sidebar.jsons.kraken_feast_icon_json,
            view: KrakenFeastMissionWindow,
            startWindow: KrakenFeastMissionWindow,
            finishWindow: KrakenFeastMissionWindow
        });

        Missions[Mission.TYPE_BOOSTTIME] = Object.assign({}, BaseMissionData, {
            available: {
                level: 9.5
            },
            name: "BoostTime",
            duration: "1 day",
            semaphore: Missions.SEMAPHORE_SECONDARY,
            bundle: "boosttime",
            sideBarJson: bundles.sidebar.jsons.boosttime_icon_json,
            view: BoostTimeMissionWindow,
            startWindow: BoostTimeMissionWindow,
            finishWindow: BoostTimeMissionWindow
        });

        Missions[Mission.TYPE_CARAVAN] = Object.assign({}, BaseMissionData, {
            available: {
                level: 8
            },
            logic: Caravan,
            name: "Caravan",
            ship: "caravanship",
            duration: "3 day",
            semaphore: Missions.SEMAPHORE_SECONDARY,
            bundle: "unitssale",
            startWindow: CaravanMissionWindow,
            view: CaravanMissionWindow,
            finishWindow: CaravanMissionWindow,
            sideBarJson: bundles.sidebar.jsons.caravan_icon_json
        });

        Missions[Mission.TYPE_EXPEDITION_PASS] = {
            name: "ExpeditionPass",
            view: ExpeditionPassWindow,
            startWindow: ExpeditionPassWindow,
            finishWindow: ExpeditionPassWindow,
            rulesOptions: ExpeditionPassRulesOptions,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("expedition_buildpass_icon_json");
            },
            logic: PassMissionLogic,
            sideBarSlotName: "expeditionBuildPassSideBarIcon",
            lastChanceView: PassLastChanceWindow,
            collectIcon: bundles.reward_icons_pass.frames.pass_star_png,
            collectItemName: "star",
            manualStart: ManualStarters.MissionTreeStarter,
            manualFinish: true,
            mainWorldReward: true
        };

        Missions[Mission.TYPE_COLLECTIONS_PASS] = {
            name: "CollectionsPass",
            view: ExpeditionPassWindow,
            startWindow: ExpeditionPassWindow,
            finishWindow: ExpeditionPassWindow,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("expedition_buildpass_icon_json");
            },
            logic: PassMissionLogic,
            sideBarSlotName: "expeditionBuildPassSideBarIcon",
            lastChanceView: PassLastChanceWindow,
            collectIcon: bundles.reward_icons_pass.frames.pass_star_png,
            collectItemName: "star",
            duration: "10 days",
            cooldown: "7 days",
            manualStart: ManualStarters.clPassStarter,
            expedition: "collections",
            available: {
                gameLevel: 9
            }
        };

        Missions[Mission.TYPE_EXPEDITION_FEAST] = {
            name: "ExpeditionFeast",
            view: ExpeditionFeastWindow,
            startWindow: ExpeditionFeastWindow,
            finishWindow: ExpeditionFeastWindow,
            manualStart: ManualStarters.ExpeditionFeastStarter,
            logic: FeastExpeditionLogic,
            duration: "1 hour",
            bundle: "lives_feast",
            prize: RewardsConfig.ExpeditionFeast.places,
            mainWorldReward: true,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("expedition_feast_icon_json") || bundles.sidebar.jsons.expedition_feast_icon_json;
            },
            collectIcon: function () {
                return cleverapps.skins.getSlot("expedition_feast_icon") || bundles.reward_icons.frames.expedition_feast_icon;
            },
            sideBarSlotName: "expeditionFeastSideBarIcon",
            competition: {
                step: [2, 2, 2, 3, 3, 3, 5, 5, 5, 10, 10, 12, 12, 15],
                forceCoef: 2,
                amount: [20, 30],
                noPlayerEpisodeCoef: true,
                botAheadPlayerCoef: 0.5,
                offlineCoef: 0.8,
                botAheadPlayerBigDifferenceCoef: 0.3,
                bigDistanceLength: 50
            }
        };

        Missions[Mission.TYPE_SNAIL_FEAST] = {
            name: "SnailFeast",
            view: SnailFeastWindow,
            startWindow: SnailFeastWindow,
            finishWindow: SnailFeastWindow,
            manualStart: ManualStarters.SnailFeastStarter,
            logic: FeastExpeditionLogic,
            duration: "6 hour",
            bundle: "snail_feast",
            prize: RewardsConfig.SnailFeast.places,
            mainWorldReward: true,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("snail_feast_icon_json") || bundles.sidebar.jsons.snail_feast_icon_json;
            },
            collectIcon: function () {
                return cleverapps.skins.getSlot("snail_feast_icon") || bundles.reward_icons.frames.snail_feast_icon;
            },
            sideBarSlotName: "expeditionFeastSideBarIcon",
            competition: {
                step: [3, 3, 3, 3, 10, 10, 10, 65, 65],
                forceCoef: 0.45,
                amount: 5,
                noPlayerEpisodeCoef: true,
                botAheadPlayerCoef: 0.3,
                offlineCoef: 0.8,
                botAheadPlayerBigDifferenceCoef: 0.1,
                bigDistanceLength: 50
            }
        };

        TravelBook.INIT_EXPEDITIONS();
    }

    if (typeof SlotMachineScene !== "undefined") {
        Missions[Mission.TYPE_SLOT_MACHINE] = Object.assign({}, BaseMissionData, {
            name: "SlotMachine",
            startWindow: undefined,
            view: SlotMachineScene,
            semaphore: Missions.SEMAPHORE_PRIMARY,
            available: {
                level: 3.85
            },
            logic: SlotMachineMissionLogic,
            sideBarJson: bundles.sidebar.jsons.slot_machine_json,
            duration: "3 days",
            iconForce: Forces.SLOT_MACHINE_ICON
        });
    }

    if (features.includes("pinball")) {
        Missions[Mission.TYPE_PINBALL] = Object.assign({}, BaseMissionData, {
            name: "Pinball",
            startWindow: undefined,
            abTest: cleverapps.ABTest.PINBALL,
            view: PinballScene,
            semaphore: Missions.SEMAPHORE_PRIMARY,
            available: {
                level: 3.85
            },
            logic: PinballMissionLogic,
            sideBarJson: bundles.sidebar.jsons.pinball_json,
            duration: "3 days",
            iconForce: Forces.PINBALL_ICON,
            collectIcon: bundles.reward_icons.frames.pinball_token_png,
            minigame: {
                price: 1
            },
            prize: undefined
        });
    }

    if (cleverapps.config.type === "match3") {
        Missions[Mission.TYPE_LEVELPASS] = Object.assign({}, BaseMissionData, {
            priority: 4,
            name: "LevelPass",
            view: LevelPassWindow,
            semaphore: Missions.SEMAPHORE_PRIMARY,
            iconForce: Forces.LEVELPASS,
            startWindow: LevelPassWindow,
            finishWindow: LevelPassWindow,
            rulesOptions: {
                name: "PassRulesWindow",
                bundle: bundles.passruleswindow
            },
            logic: PassMissionLogic,
            sideBarJson: bundles.sidebar.jsons.levelpass_icon_json,
            sideBarProgressIconJson: bundles.sidebar.jsons.levelpass_star_json,
            lastChanceView: PassLastChanceWindow,
            collectItemName: "star",
            duration: "7 days",
            available: {
                level: 14
            }
        });
    }

    Missions.afterInitialize();
};

Missions.checkIllegal = function (type) {
    return type === undefined || (type.includes && type.includes("undefined"));
};

Missions.afterInitialize = function () {
    var missions = Object.keys(Missions).filter(function (type) {
        return typeof Missions[type] === "object" && Missions[type] !== Missions.FEATURES;
    });

    missions.forEach(function (parentType) {
        parentType = cleverapps.castType(parentType);

        var parentMission = Missions[parentType];
        parentMission.type = parentType;
        var childMissions = parentMission.childMissions || [];

        childMissions.forEach(function (childData) {
            var type = childData.type !== undefined ? childData.type : childData;
            var compoundType = Mission.CompoundType(type, parentType);
            var childParams = {
                type: compoundType,
                parentType: parentType,
                implementations: undefined
            };

            if (childData.startFog) {
                childParams.manualStart = ManualStarters.FogStarter;
                childParams.startFog = childData.startFog;
            }
            if (childData.startUnit) {
                childParams.manualStart = ManualStarters.UnitStarter;
                childParams.startUnit = childData.startUnit;
            }
            if (childData.rulesOptions !== undefined) {
                childParams.rulesOptions = childData.rulesOptions;
            }
            Missions[compoundType] = Object.assign({}, Missions[type], childParams);

            if (!Missions[type].implementations) {
                Missions[type].implementations = [];
            }
            Missions[type].implementations.push(compoundType);
        });
    });

    missions.forEach(function (type, ind) {
        if (Missions.checkIllegal(type)) {
            cleverapps.throwAsync("Undefined mission type " + type + " ind: " + ind);
        }
    });
    Object.keys(PassLevelsConfig).forEach(function (type, ind) {
        if (Missions.checkIllegal(type)) {
            cleverapps.throwAsync("Undefined pass config type " + type + " ind: " + ind);
        }
    });
};

if (typeof cc === "undefined") {
    module.exports = {
        Missions: Missions,
        Mission: Mission
    };
}
