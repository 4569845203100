/**
 * Created by olga on 12.03.2024
 */

var MarkView = cc.Sprite.extend({
    ctor: function (mark) {
        this.mark = mark;
        this._super(bundles.yatzy.frames.mark_bg_png);

        var markImage = this.image = this.getMarkImage(mark);
        this.addChild(markImage);

        this.setLocalZOrder(10);
        this.setPositionRound(cleverapps.styles.MarkView);

        mark.on("collect", this.createListener(this.collect.bind(this)), this);
    },

    collect: function (targetName, callback) {
        var sixShine = new cleverapps.Spine(bundles.yatzy.jsons.small_six_shine_json);
        sixShine.setPositionRound(cleverapps.styles.MarkView.icon);
        sixShine.setAnimation(0, "animation", false);
        this.addChild(sixShine);
        this.image.removeFromParent();

        sixShine.setCompleteListenerRemove(function () {
            var flyIcon = this.getMarkImage();
            var target = cleverapps.aims.getTarget(targetName);

            this.addChild(flyIcon);
            flyIcon.replaceParentSamePlace(cleverapps.scenes.getMovingNode());

            this.runAction(
                new cc.Sequence(
                    new cc.ScaleTo(0.3, 0.3),
                    new cc.RemoveSelf()
                )
            );

            flyIcon.runAction(
                new cc.Sequence(
                    new cc.Spawn(
                        AnimationsLibrary.animateCollect(flyIcon, target, {
                            duration: 0.5
                        }),
                        new cc.Sequence(
                            new cc.CallFunc(function () {
                                cleverapps.audio.playSound(bundles.game.urls.yatzy_tournament);
                            }),
                            new cc.DelayTime(0.35),
                            new cc.CallFunc(function () {
                                if (callback) {
                                    callback();
                                }
                            })
                        )
                    ),
                    new cc.RemoveSelf()
                )
            );
        }.bind(this));
    },

    getMarkImage: function () {
        var icon = new cc.Sprite(this.mark.getImage());
        icon.setPositionRound(cleverapps.styles.MarkView.icon);
        return icon;
    }
});

cleverapps.styles.MarkView = {
    x: { align: "left", dx: -34 },
    y: { align: "bottom", dy: -34 },
    icon: {
        x: { align: "center", dx: 1 },
        y: { align: "center", dy: 2 }
    }
};
